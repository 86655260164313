import React from 'react';
import SEO from "../../components/seo";
import Layout from "../../components/layout";
import { Link } from 'gatsby';
import {Helmet} from "react-helmet";
import Iframe from 'react-iframe';
import { Title, Twitter } from "../../components/Globals";

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// import 'react-awesome-slider/src/styles.js';
import { FaAngleDoubleDown } from "react-icons/fa";

const ItSecurityPage = () => {
  
    const ref = React.createRef();

    const arrowClick = () =>
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });


  return (
        <Layout>
             <Helmet>
        <title>IT Security Services | Cymetrix Software</title>
        <meta name="description" content="Cymetrix Software provides end-to-end security solutions and help organizations reduce the cost and complexity of securing infrastructure."/>
        <meta name="keywords" content="cymetrix,IT Security, Cyber security, IT security services India, Cymetrix, cymetrixsoft, Cymetrixsoftware"/>
    
      </Helmet>
            <div className="it-security-background flex flex-col">
            <h1 className=" xl:text-4xl xl:m-56  md:m-16 sm:m-24 lg:text-4xl md:text-3xl sm:text-3xl sma:text-xl
                text-white text-uppercase text-center">
                  SECURE THE DIGITAL TRANSFORMATION OF YOUR BUSINESS
                    </h1>
                <button class="focus:outline-none " >
         <FaAngleDoubleDown className=" w-32 h-20 py-5 text-white" onClick={arrowClick}/>
        </button>
            </div>
            <div className="relative max-w-full mx-auto" style={{backgroundColor: '#f6f6f6'}} ref={ref}>
                <div className="flex flex-col lg:flex-row md:items-center max-w-screen-xl mx-auto py-20 md:py-24 justify-center">
                    <div className="sma:mx-3 relative mt-12 lg:mt-0 flex flex-col justify-center">
                        <div className="flex justify-center md:justify-end items-center relative max-w-3xl lg:max-w-none">
                            <img
                                className="h-64 w-64"
                                src={require('./../../images/services/saleforceApiImg.png')}
                                alt="Hero"
                                />
                        </div>
                    </div>
                    <div className="sma:mx-3 relative lg:w-8/12 flex-shrink-0 lg:text-lef mx-auto justify-center">
                        <h1 className="font-black text-3xl leading-snug max-w-3xl text-hover font-serif">
                            IT Infrastructure, Networking and Security
                        </h1><br />
                        <hr className="border-solid border-black mb-5" style={{borderWidth: 1}}/>
                        <p className="font-hairline">Security of Enterprise IT infrastructure has become paramount with the rapid expansion of networks, technological advancements with greater reach to end-users. Our IT Security Services will help organizations to reduce the cost and complexity of securing infrastructure. Our global approach enables your organization to understand its current risk exposure and make informed decisions for continuous risk management.
                        </p>
                    </div>
                </div>

                <div className="flex flex-col mx-24 items-center rounded-lg pb-10">
                    <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1"/>
                    <div className="px-6 py-4 ">
                        <h4 class="text-lg"><b>What We Offer</b></h4>
                        <p class="text-justify">Cymetrix Software provides end to end security solutions in the form of consultancy for design of the security architecture, selection of security devices and tools, testing, security implementation and management for the enterprise.</p>
                    </div>
                </div>
            </div>
           
            <div className="product-background w-full flex flex-col ">
            <h1 class=" px-12 mt-4 sm:w-1/2 text-2xl text-white font-bold">IT Security Services By Cymetrix</h1>

            <div class="flex flex-col sm:flex-row sm:-mx-2">
   
    <div class=" px-12 mt-4 sm:w-1/2 ">
    
                        <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col sm:mx-2 sm:p-3 md:p-6">
                       
                            <div className="text-white text-center text-lg">MANAGED SECURITY SERVICES</div>
                            <hr className="h-px bg-white w-24 md:mx-48 mx-24 mt-4 mb-3"></hr>
                            <div className="mt-2 text-base text-gray-300">
                              <ul className="list-disc text-base list-outside leading-relaxed px-4">
                                <li> VA/PT</li>
                                <li> DIGITAL FORENSICS AND FRAUD INVESTIGATION</li>
                                <li> EMPLOYEE BEHAVIOUR ANALYTICS</li>
                                <li> EMERGENCY RESPONSE AND INCIDENT MANAGEMENT</li>
                              </ul>
                            </div>
                        </div>
                    </div>
                    <div className=" px-12 mt-4 sm:w-1/2 ">
                    <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col  sm:mx-2 sm:p-3 md:p-6">
            <div className="text-white text-center text-lg">CORE MISSION</div>
            <hr className="h-px bg-white w-24 mt-4 md:mx-56 m-24 mb-3"></hr>
            <div className="mt-2 text-base text-gray-300">
            
              <ul class=" ml-2 list-disc list-inside">
                                <li>APPLICATION SECURITY TESTING</li>
                                <li>SERVER SECURITY TESTING</li>
                                <li>NETWORK PENETRATION TESTING</li>
                                <li>INFRASTRUCTURE PENETRATION TESTING</li>
                                <li>CLOUD SECURITY TESTING</li>
                              
                              </ul>
            </div>
          </div>
        </div>
        
      
                    </div>
                    <div class="flex flex-col sm:flex-row sm:-mx-2 mt-20">
    <div class=" px-12 mt-2 sm:w-1/2  ">
                        <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col  sm:mx-2 sm:p-3 md:p-6 ">
                            <div className="text-white text-center text-lg">GOVERNANCE RISK AND COMPLIANCE</div>
                            <hr className="h-px bg-white w-24 mt-4 md:mx-56 mx-24 mb-3"></hr>
                            <div className="mt-2 text-base text-gray-300">
                              <ul className="list-disc text-base list-outside leading-relaxed px-4">
                                <li>PCI-DSS</li>
                                <li>ISO 27000 SERIES</li>
                                <li>HIPAA</li>
                                <li>COBIT 5</li>
                                <li>SOC 2</li>
                                <li>GDPR</li>
                              </ul>
                            </div>
                        </div>
                    </div>
                    <div className=" px-12 mt-2 sm:w-1/2 ">
                    <div class="bg-transparent border p-8 h-full rounded-sm flex flex-col sm:mx-2 sm:p-3 md:p-6">
            <div className="text-white text-center text-lg">TRAINING</div>
            <hr className="h-px bg-white w-24 mt-4 md:mx-56 mx-24 mb-3"></hr>
            <div className="mt-2 text-base text-gray-300">
              <ul class=" ml-2 list-disc list-inside">
                                <li>CYBER SECURITY AWARENESS</li>
                                <li>CERTIFIED SECURITY TESTER</li>
                                <li>CERTIFIED CYBER SECURITY ANALYST</li>
                                <li>INFORMATION SECURITY CERTIFIED PROFESSIONAL</li>
                              
                              </ul>
            </div>
          </div>
        </div>
        
                    </div>
                    

                    <div className="flex flex-col items-center sm:flex-row justify-center mt-6  ">
                  <Link to="/contact-us" className="uppercase px-8 lg:px-10 py-3 rounded bg-transparent text-white focus:shadow-outline border border-yellow-500 hover:border-blue-100  transition duration-300 mb-8 ">
                    Contact Cymetrix
                  </Link>
                </div>
                    </div>

                   
   
    

            <div className="flex flex-col mx-auto max-w-6xl sma:my-3">
                <img src={require('./../../images/case-studies/cs-border.jpg')} className="w-full h-1 sma:my-2 my-5"/>
            </div>
            <Twitter />
        </Layout>
    );
}

export default ItSecurityPage;